import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import { karantina, roboto } from "@/utis/fonts";
import { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useTranslations } from "next-intl";
import ImageChanger from "./ImageChanger";

export interface sectionType {
  title: string;
  description: string;
  imageUrl: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  background: theme.palette.customBackground.cards,
}));

const StyledWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",
  background: "transparent",
  width: "100%",
  position: "sticky",
  top: 0,
  padding: `${theme.spacing(15)} ${theme.spacing(5)}`,
  ".slider-text": {
    opacity: 0,
    transition: "all .3s ease-in-out",
  },
  ".slider-image": {
    opacity: 0,
    transform: "translateY(250px) scale(0.8)",
    transition: "all .3s linear",
  },
  "&.animated": {
    ".slider-text": {
      opacity: 1,
    },
    ".slider-image": {
      opacity: 1,
      transform: "translateY(0) scale(1)",
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

const StyledWrapperInner = styled(Box)(
  () => `
  height: 100%;
  position: relative;
`,
);

const StyledItemWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  columnGap: `${theme.spacing(3)}`,
  padding: `${theme.spacing(5)}`,
  "&.first-item": {
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.customBackground.cards})`,
    borderRadius: `${theme.spacing(2)}`,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: `${theme.spacing(2)}`,
  },
}));

const StyledTextWrapper = styled(Box)(({ theme }) => ({
  flex: " 0 0 485px",
  [theme.breakpoints.down("md")]: {
    flex: "0 0 fit-content",
  },
}));

const StyledImageWrapper = styled(Box)`
  flex: 1 0;
  height: 100%;
`;

const StyledImage = styled(Image)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

const StyledTitle = styled(Typography)(
  ({ theme }) => `
  font-size: 80px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${karantina.style.fontFamily};
  ${theme.breakpoints.down("md")} {
    font-size: 56px;
  }
`,
);

const StyledDesc = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "28px",
  color: `${theme.palette.text.secondary}`,
  marginTop: ` ${theme.spacing(3)}`,
  fontFamily: `${roboto.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: ` ${theme.spacing(2)}`,
  },
}));

const StyledProgressWrapper = styled(Box)(
  () => `
    position: absolute;
    max-width: 1200px;
    margin: 0 auto;
    inset: 0;
  `,
);

const StyledProgressBar = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(4)};
    width: 6px;
    background: ${theme.palette.customBackground.progress};
    border-radius: 100px;
    height: 50vh;
    top: 50%;
    transform: translateY(-50%);
    ${theme.breakpoints.down("md")} {
      left: ${theme.spacing(4)};
      right: ${theme.spacing(4)};
      top: unset;
      bottom: ${theme.spacing(1)};
      width: unset;
      height: 6px;
      transform: unset;
    }
  `,
);

const StyledProgress = styled(Box)(
  ({ theme }) => `
    height: 0;
    max-height: 100%;
    background: linear-gradient(to bottom, ${theme.palette.text.primary}, ${theme.palette.text.secondary});
    border-radius: 100px;
    transition: height .2s linear;
    ${theme.breakpoints.down("md")} {
      height: 6px;
      width: 0;
      transition: width .2s linear;
      background: linear-gradient(to right, ${theme.palette.text.primary}, ${theme.palette.text.secondary});
      max-height: unset;
      max-width: 100%;
    }
  `,
);

const StyledProgressIndex = styled(Typography)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  fontSize: "14px",
  lineHeight: "20px",
  "&.top": {
    top: 0,
    transform: "translate(-50%, -130%)",
  },
  "&.bottom": {
    bottom: 0,
    transform: "translate(-50%, 130%)",
  },

  [theme.breakpoints.down("md")]: {
    "&.top": {
      top: "50%",
      left: 0,
      transform: "translate(-120%, -50%)",
    },
    "&.bottom": {
      top: "50%",
      bottom: "unset",
      left: "unset",
      right: 0,
      transform: "translate(120%, -50%)",
    },
  },
}));

gsap.registerPlugin(ScrollTrigger);

function ArbitrageBetting() {
  const [activeIndex, setActiveIndex] = useState(1);
  const t = useTranslations("Home");
  const theme = useTheme();
  const arbitrageSections = t.raw("arbitrage_sections");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const wrapNumber = (text: string) => {
    const fontSize = isMobile ? "66px" : "92px";
    return text.replace(/450/, `<span style="font-size: ${fontSize};">450</span>`);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const { top } = wrapperRef.current.getBoundingClientRect();
        const progressBar = progressRef.current;
        if (progressBar) {
          const wrapperPercent = `${(-top / (wrapperRef.current.clientHeight - window.innerHeight)) * 100}%`;
          if (isMobile) {
            progressBar.style.width = wrapperPercent;
            // console.log("width", (-top / (wrapperRef.current.clientHeight - window.innerHeight)) * 100);
          } else {
            progressBar.style.height = wrapperPercent;
          }
        }
        if (top < 0) {
          const sliderItems = Array.from(wrapperRef.current.children);
          const activeItems = sliderItems.filter((sliderItem) => {
            sliderItem.classList.remove("animated");
            return sliderItem.getBoundingClientRect().top <= 0;
          });
          const { length } = activeItems;
          if (length > 0) {
            const tempActiveIndex = activeItems[length - 1].classList.contains("last") ? length - 2 : length - 1;
            setActiveIndex(tempActiveIndex + 1);
            activeItems[tempActiveIndex].classList.add("animated");
          }
        }
      }
    };

    if (wrapperRef.current) {
      const sliderItems = wrapperRef.current.children;
      sliderItems[0].classList.add("animated");
    }

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  return (
    <StyledBox>
      <Box ref={wrapperRef}>
        {arbitrageSections.map((section: sectionType, index: number) => (
          <StyledWrapper className="slider-item" key={index}>
            <StyledWrapperInner ref={sectionRef}>
              <StyledItemWrapper className={`${index === 0 ? "first-item" : ""}`}>
                <StyledTextWrapper className="slider-text">
                  <StyledTitle dangerouslySetInnerHTML={{ __html: wrapNumber(section.title) }} />
                  <StyledDesc>{section.description}</StyledDesc>
                </StyledTextWrapper>
                <StyledImageWrapper className="slider-image">
                  {Array.isArray(section.imageUrl) ? (
                    <ImageChanger srcList={section.imageUrl} alt={section.title} width={800} height={800} />
                  ) : (
                    <StyledImage src={section.imageUrl} alt={section.title} width={800} height={800} />
                  )}
                </StyledImageWrapper>
                {index === 0 && (
                  <StyledProgressWrapper>
                    <StyledProgressBar>
                      <StyledProgressIndex className="top">{`00${activeIndex}`.slice(-2)}</StyledProgressIndex>
                      <StyledProgress ref={progressRef} />
                      <StyledProgressIndex className="bottom">{`00${arbitrageSections.length}`.slice(-2)}</StyledProgressIndex>
                    </StyledProgressBar>
                  </StyledProgressWrapper>
                )}
              </StyledItemWrapper>
            </StyledWrapperInner>
          </StyledWrapper>
        ))}
        <StyledWrapper className="slider-item last" />
      </Box>
    </StyledBox>
  );
}

export default ArbitrageBetting;
