export interface UTMContentSection {
    type: 'text' | 'video' | 'link' | 'textWithLink';
    content: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalProps?: any;
}


export interface UTMContent {
    title: string;
    sections: UTMContentSection[];
}

export function getContentByUTMSource(utmSource: string, t: (key: string) => string): UTMContent {
    const contentMap: { [key: string]: UTMContent } = {
        'zerohedge': {
            title: t('zerohedge.title'),
            sections: [
                { type: 'text', content: t('zerohedge.intro') },
                { type: 'video', content: 'https://player.vimeo.com/video/1013508106?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
                { type: 'text', content: t('zerohedge.description1') },
                { type: 'textWithLink', content: '', additionalProps: { href: '/en/resources', linkText: t('learn'), textBefore: t('zerohedge.description2_asc'), textAfter: t('zerohedge.description2_desc') } },
                { type: 'text', content: t('zerohedge.callToAction') },
            ],
        },
        // Add more sources as needed
    };

    return contentMap[utmSource] || {
        title: t('default.title'),
        sections: [
            { type: 'text', content: t('default.intro') },
            { type: 'video', content: 'https://player.vimeo.com/video/1013508106?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
            { type: 'text', content: t('default.description1') },
            { type: 'textWithLink', content: '', additionalProps: { href: '/en/resources', linkText: t('learn'), textBefore: t('default.description2_asc'), textAfter: t('default.description2_desc') } },
            { type: 'text', content: t('default.callToAction') },
        ],
    };
}