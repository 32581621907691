import React, { useRef } from "react";
import { Box, Typography, Button, styled, Stack, Link, useMediaQuery, useTheme } from "@mui/material";
import { useTranslations } from "next-intl";
import { UTMContent, UTMContentSection } from "./UTMContent";
import BookInfoIcon from "../Icons/BookInfoIcon";


const StyledVideoWrapper = styled(Box)(() => ({
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    height: 0,
    overflow: "hidden",
    margin: "0 auto",
}));

const StyledButton = styled(Button, {
    shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
    ({ theme, textColor }) => `
    height: 42px;
    width: fit-content;
    font-size: ${theme.typography.body2.fontSize};
    font-weight: bold;
    align-self: center;
    padding: ${theme.spacing(2)};
    ${theme.breakpoints.down("md")} {
        padding: ${theme.spacing(0.5)};
    }
  `,
);

function UTMContentRenderer({ content }: { content: UTMContent }) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const t = useTranslations("utm.zerohedge");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const renderSection = (section: UTMContentSection, index: number) => {
        switch (section.type) {
            case 'text':
                return <Typography key={index} variant={isMobile ? "caption" : "body2"}>{section.content}</Typography>;
            case 'video':
                return (
                    <StyledVideoWrapper key={index}>
                        <iframe
                            ref={iframeRef}
                            src={section.content}
                            title="UTM Content Video"
                            width="100%"
                            height="100%"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            style={{ border: 0, top: 0, left: 0, position: "absolute", }}
                        />
                    </StyledVideoWrapper>
                );
            case 'link':
                return (
                    <Typography key={index} variant={isMobile ? "caption" : "body2"} textAlign="left" my={1}>
                        <BookInfoIcon sx={{ fontSize: "12px" }} />
                        <Link href={section.additionalProps.href} sx={{ textDecoration: "none", fontWeight: "bold" }} color="textPrimary">{section.content}</Link>
                    </Typography>
                );
            case 'textWithLink':
                return (
                    <Typography key={index} variant={isMobile ? "caption" : "body2"}>
                        {section.additionalProps.textBefore}
                        <span style={{ whiteSpace: "nowrap" }}>
                            <BookInfoIcon sx={{ fontSize: "12px" }} />
                            <Link
                                href={section.additionalProps.href}
                                color="textPrimary"
                                sx={{ textDecoration: "none", fontWeight: "bold" }}
                            >
                                {section.additionalProps.linkText}
                            </Link>
                        </span>
                        {section.additionalProps.textAfter}
                    </Typography>
                );
            default:
                return null;
        }
    };

    return (
        <Box width="100%" p={isMobile ? 1 : 2}>
            <Stack justifyContent="center">
                <Typography variant="header" textAlign="center" pb={isMobile ? 1 : 2}>{content.title}</Typography>
            </Stack>
            <Stack direction="column" gap={isMobile ? 0.5 : 1} py={1}>
                {content.sections.map((section, index) => renderSection(section, index))}
            </Stack>
            <Stack justifyContent="center">
                <StyledButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    href="/user-control-panel">{t("get_started")}</StyledButton>
            </Stack>
        </Box>
    );
}

export default UTMContentRenderer;